import { useMutation, useQueryClient } from '@1kit/ui/data'
import { useInvalidateCurrentUser } from '@1kit/ui/data/user'
import { createReward, updateReward } from 'utils/apiv2/fetchRewards'
import { startRewardClaimAsClaimV1 } from 'utils/apiv2/startRewardClaim'
import { Reward } from 'utils/apiv2/types'

export function useClaimRewardMutation(id: number | undefined) {
  const client = useQueryClient()
  const invalidateCurrentUser = useInvalidateCurrentUser()
  return useMutation({
    mutationKey: ['/rewards/claim', id],
    async onSuccess() {
      invalidateCurrentUser()
      return Promise.all([
        client.invalidateQueries({ queryKey: ['/rewards', id] }),
        client.invalidateQueries({ queryKey: ['/rewards'] }),
      ])
    },
    async mutationFn() {
      if (id) {
        return startRewardClaimAsClaimV1(id)
      }
      return undefined
    },
  })
}

export function useRewardMutation(id?: number) {
  const client = useQueryClient()
  return useMutation({
    mutationKey: ['updateReward', id],
    onSuccess() {
      return Promise.all([
        client.invalidateQueries({ queryKey: ['/rewards', id] }),
        client.invalidateQueries({ queryKey: ['/rewards'] }),
      ])
    },
    mutationFn(reward: Partial<Reward>) {
      if (id) {
        return updateReward(id, reward)
      }
      return createReward(reward)
    },
  })
}
