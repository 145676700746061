import { request } from '@1kit/api/request'
import { fetchGames, fetchGame } from './fetchGames'
import { transformRewardToCollectible } from './transformations'
import { Reward } from './types'

export async function fetchRewards() {
  return request<Reward[]>('/rewards')
}

export function fetchReward(id: number) {
  return request<Reward>(`/rewards/${id}`)
}

export async function fetchRewardsCollectibles() {
  const rewards = await fetchRewards()
  const games = await fetchGames()
  return rewards.map((reward) => {
    const game = games.find((g) => g.id === reward.gameId)
    if (!game) {
      console.error(`Game with id ${reward.gameId} not found`)
      return null
    }
    return transformRewardToCollectible(reward, game)
  }).filter((r) => !!r)
}

export async function fetchRewardCollectible(id: number) {
  const reward = await fetchReward(id)
  const game = await fetchGame(reward.gameId)
  return transformRewardToCollectible(reward, game)
}

export async function fetchCollectiblesByGameId(gameId: number | undefined) {
  if (!gameId) {
    return []
  }
  const game = await fetchGame(gameId)
  const rewards = await fetchRewardsByGameId(gameId)
  return rewards.map((reward) => transformRewardToCollectible(reward, game))
}

export function fetchRewardsByGameId(gameId: number) {
  return request<Reward[]>(`/games/${gameId}/rewards`)
}

export function updateReward(id: number, reward: Partial<Reward>) {
  return request<Reward>(`/rewards/${id}`, {
    method: 'PATCH',
    body: reward,
  })
}

export function createReward(reward: Partial<Reward>) {
  return request<Reward>('/rewards', {
    method: 'POST',
    body: reward,
  })
}
